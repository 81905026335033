import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(advancedFormat);
dayjs.extend(isBetween);
dayjs.extend(minMax);
dayjs.extend(timezone);
dayjs.extend(utc);

// internal only. we do not want to expose dayjs
function dayjsFromISO(datetimeStr, inputTimezone = 'utc') {
  const userTimezone = dayjs.tz.guess();

  if (inputTimezone === 'utc') {
    // let's use the full ISO string
    return dayjs(datetimeStr);
  }

  if (inputTimezone === 'local') {
    // ignore time from datetime
    // e.g., pet birthdate -> 2020-01-15 00:00:00 should be interpreted as local date,
    // even if it's stored as datetime utc in the database for some reason.
    return dayjs.tz(datetimeStr.split('T')[0], userTimezone);
  }

  // assumes datetime from an specific timezone
  // e.g., policy_start_date. Although stored as YYYY-MM-DD 00:00:00, the value
  // represents an America/New_York datetime.
  return dayjs.tz(datetimeStr, inputTimezone);
}

// internal only. we do not want to expose dayjs
function dayjsToString(dayjsObj, format = 'MM/DD/YYYY') {
  const userTimezone = dayjs.tz.guess();
  return dayjsObj.tz(userTimezone).format(format);
}

// GENERIC FUNCTIONS
export function currentISODate(offsetTimezone = 'utc') {
  if (offsetTimezone === 'utc') {
    // no offset = +00:00
    return dayjs().utc().format();
  }

  if (offsetTimezone === 'local') {
    // local offset
    // -04:00 if America/New_York
    // -07:00 if America/Los_Angeles
    const userTimezone = dayjs.tz.guess();
    return dayjs().tz(userTimezone).format();
  }

  // use specific offset
  return dayjs().tz(offsetTimezone).format();
}

export function formatISODate(
  datetimeStr,
  { inputTimezone = 'utc', format = 'MM/DD/YYYY' } = {}
) {
  const date = dayjsFromISO(datetimeStr, inputTimezone);
  return dayjsToString(date, format);
}

export function calculateAndFormatISODate(
  datetimeStr,
  operations,
  { inputTimezone = 'utc', format = 'MM/DD/YYYY' } = {}
) {
  const re = /([+-]?\d+)(?:\s*)([a-z]+)/gi;
  const ops = [...operations.matchAll(re)].map(op => {
    return { amount: op[1], unit: op[2] };
  });

  let date = dayjsFromISO(datetimeStr, inputTimezone);

  ops.forEach(op => {
    date = date.add(op.amount, op.unit);
  });

  return dayjsToString(date, format);
}

export function diffSince(
  datetimeStr,
  unit = 'day',
  { inputTimezone = 'utc' } = {}
) {
  const date = dayjsFromISO(datetimeStr, inputTimezone);
  let now;

  if (inputTimezone === 'utc') {
    now = dayjs().utc();
  } else if (inputTimezone === 'local') {
    const userTimezone = dayjs.tz.guess();
    now = dayjs().tz(userTimezone);
  } else {
    now = dayjs().tz(inputTimezone);
  }

  return now.diff(date, unit);
}

// BUSINESS FUNCTIONS
export function getEffectiveDate(format = 'MMMM Do, YYYY') {
  // Effective date is NY date + 1 day
  return dayjs().tz('America/New_York').add(1, 'd').format(format);
}

export function getAccidentEffectiveDate({
  period = 14,
  format = 'MMMM Do, YYYY',
} = {}) {
  // Accident effective date is NY date + days in accident section from programs
  return dayjs(getEffectiveDate('YYYY-MM-DD')).add(period, 'd').format(format);
}

export function getIllnessEffectiveDate({
  period = 14,
  format = 'MMMM Do, YYYY',
} = {}) {
  // Illness effective date is NY date + days in illness section from programs
  return dayjs(getEffectiveDate('YYYY-MM-DD')).add(period, 'd').format(format);
}

export function formatTemporaryDate(datetimeStr, format = 'MMMM Do, YYYY') {
  // When AAPAALD, BE returns all temporary dates and only formatting is needed
  return dayjs(datetimeStr, 'America/New_York').format(format);
}

// todo: review policy_illness_XYZ_date usage when removing flag fer3172ModelLaw - _effective_ is deprecated
export function getDateRangeLabel(policy) {
  const formattedDates = formatPolicyDates(
    policy.policy_effective_date,
    policy.policy_end_date,
    policy.policy_illness_coverage_start_date || policy.policy_illness_effective_date
  );
  return `${formattedDates?.startDate} - ${formattedDates?.endDate}`;
}

// todo: review policy_illness_XYZ_date usage when removing flag fer3172ModelLaw - _effective_ is deprecated
export function getYearRangeLabel(policy) {
  const formattedDates = formatPolicyDates(
    policy.policy_effective_date,
    policy.policy_end_date,
    policy.policy_illness_coverage_start_date ||
      policy.policy_illness_effective_date,
    'YYYY'
  );

  return `${formattedDates?.startDate} - ${formattedDates?.endDate}`;
}

export function formatPolicyDates(
  startDate,
  endDate,
  illnessEffectiveDate,
  format = 'MM/DD/YYYY'
) {
  const formattedStartDate = formatISODate(startDate, {
    inputTimezone: 'local',
    format: format,
  });

  const formattedEndDate = calculateAndFormatISODate(endDate, '-1d', {
    inputTimezone: 'local',
    format: format,
  });

  const formattedIllnessEffectiveDate = formatISODate(illnessEffectiveDate, {
    inputTimezone: 'local',
    format: format,
  });

  return {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    illnessEffectiveDate: formattedIllnessEffectiveDate,
  };
}

export function formatPlanDates(startDate, format = 'MM/DD/YYYY') {
  const formattedStartDate = formatISODate(startDate, {
    inputTimezone: 'local',
    format: format,
  });

  const formattedEndDate = calculateAndFormatISODate(startDate, '+1y -1d', {
    inputTimezone: 'local',
    format: format,
  });

  return {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  };
}

export function formatPetBirthdate(datetimeStr, format = 'MM/DD/YYYY') {
  return formatISODate(datetimeStr, {
    inputTimezone: 'local',
    format: format,
  });
}

export function formatNextChargeDate(datetimeStr, format = 'MMM D, YYYY') {
  return formatISODate(datetimeStr, {
    inputTimezone: 'local',
    format: format,
  });
}

export function formatMMDDYYYYtoYYYYMMDD(dateStr) {
  return formatISODate(dateStr, {
    inputTimezone: 'local',
    format: 'YYYY-MM-DD',
  });
}

export function dateIsBefore(datetimeStr, beforeDatetimeStr) {
  return dayjs(datetimeStr).isBefore(dayjs(beforeDatetimeStr));
}

export function dateIsAfter(datetimeStr, afterDatetimeStr) {
  return dayjs(datetimeStr).isAfter(dayjs(afterDatetimeStr));
}

export function dateIsToday(datetimeStr) {
  return dayjs().isSame(dayjs(datetimeStr), 'day');
}

export function dateIsBetween(
  datetimeStr,
  startDatetimeStr,
  endDatetimeStr,
  inclusivity = '[)'
) {
  return dayjs(datetimeStr).isBetween(
    dayjs(startDatetimeStr),
    dayjs(endDatetimeStr),
    null,
    inclusivity
  );
}
