import { useMutation, useQueryClient } from 'react-query';

import { USER_ADDRESSES_QUERY } from './user-address-query';
import { USER_PROFILE_QUERY } from './user-profile-query';
import { putUserBillingAddress, updateUserPaperless } from './user-service';

export function useMutateUserPaperless() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ is_paperless, terms_version }) =>
      updateUserPaperless({ is_paperless, terms_version }),
    {
      onSettled: () => queryClient.invalidateQueries([USER_PROFILE_QUERY]),

      onMutate: async variables => {
        await queryClient.cancelQueries([USER_PROFILE_QUERY]);

        const profileData = queryClient.getQueryData([USER_PROFILE_QUERY]);
        const { is_paperless, terms_version } = variables;

        queryClient.setQueryData([USER_PROFILE_QUERY], {
          ...profileData,

          is_paperless,
          terms_version,
        });

        return { prevData: profileData };
      },
    }
  );
}

export function useMutateUserBillingAddress() {
  const queryClient = useQueryClient();

  return useMutation(({ address }) => putUserBillingAddress(address), {
    onSuccess: async (data, variables) => {
      await queryClient.cancelQueries([USER_ADDRESSES_QUERY]);

      const addressData = queryClient.getQueryData([USER_ADDRESSES_QUERY]);

      queryClient.setQueryData([USER_ADDRESSES_QUERY], {
        ...addressData,

        billing: {
          ...addressData.billing,
          street_1: variables.address.street_1,
          street_2: variables.address.street_2,
          city: variables.address.city,
          state_province: variables.address.state,
          zipcode: variables.address.zipcode,
          country: variables.address.country,
        },
      });

      return { prevData: addressData };
    },
  });
}
