/* This object needs to be updated when BE adds new statuses */
export const CLAIM_TYPE_OPTIONS = [
  { label: 'Insurance', value: 'insurance' },
  { label: 'Prevent', value: 'prevent' },
];

export const CLAIM_STATUS_OPTIONS = [
  { label: 'Submitted', value: 'Submitted' },
  { label: 'In Review', value: 'In Review' },
  { label: 'Policy Lapsed', value: 'Policy Lapsed' },
  { label: 'Pending Records from Vet', value: 'Pending Records from Vet' },
  { label: 'Pending Information', value: 'Pending Information' },
  { label: 'Pending Denial', value: 'Pending Denial' },
  { label: 'Withdrawn', value: 'Withdrawn' },
  { label: 'Duplicate', value: 'Duplicate' },
  { label: 'Completed', value: 'Completed' },
];
