import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  ButtonStyles,
  ChevronRight,
  Heart,
  LegalBody,
  RouteLink as Link,
  Typography,
} from '@pumpkincare/shared/ui';

import { getPolicyConfig } from '../../policy-config-utils';

import styles from './pet-card.css';

function PetCard({ pet, toPet, toClaim }) {
  const { renewal, declined, cancelled, waiting, lapsed } = getPolicyConfig(
    pet.policies
  );

  return (
    <div className={styles.root}>
      <Link to={toPet} className={styles.top}>
        <div className={classNames(styles.name, Typography.h5)}>
          {pet.name}
          <ChevronRight className={styles.chevron} width={8} height={14} />
        </div>

        {cancelled.isCancelled && cancelled.isDeceased ? (
          <LegalBody className={styles.flex}>
            In loving memory{' '}
            <Heart className={styles.heart} width={21} height={16} />
          </LegalBody>
        ) : null}

        {cancelled.isCancelled && !cancelled.isDeceased ? (
          <LegalBody>Cancelled</LegalBody>
        ) : null}

        {declined.isDeclined ? (
          <LegalBody>Plan cancels on {declined.endDate}</LegalBody>
        ) : null}

        {waiting.isWaiting ? (
          <LegalBody>
            {waiting.isModelLaw ? 'Illness w' : 'W'}aiting period until{' '}
            <b>{waiting.startDate}</b>
          </LegalBody>
        ) : null}

        {renewal.isRenewal ? (
          <LegalBody>Renews on {renewal.renewalDate}</LegalBody>
        ) : null}

        {lapsed.isLapsed ? <LegalBody>Billing lapsed</LegalBody> : null}
      </Link>

      {toClaim ? (
        <div className={styles.claim}>
          <Link to={toClaim} className={classNames(styles.button, ButtonStyles.cta)}>
            File a claim
          </Link>
        </div>
      ) : null}
    </div>
  );
}

PetCard.propTypes = {
  /** pet should just be the BE response associated with a pet */
  pet: PropTypes.object.isRequired,
  /** link for viewing more pet details */
  toPet: PropTypes.string.isRequired,
  /** link for filing a claim for a pet */
  toClaim: PropTypes.string,
};

export default PetCard;
