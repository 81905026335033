import { dateIsBefore, formatISODate } from '@pumpkincare/shared';

export const POLICY_STATUS = {
  ACTIVE: 'active',
  RENEW_PENDING: 'renew_pending',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  VOIDED: 'voided',
  DECLINED: 'declined',
  PENDING: 'pending',
  LAPSED: 'lapsed',
  CANCEL_PENDING: 'cancel_pending',
};

const STATUS_MAP = {
  [POLICY_STATUS.CANCELLED]: 'Cancelled',
  [POLICY_STATUS.CANCEL_PENDING]: 'Cancelled',
  [POLICY_STATUS.VOIDED]: 'Cancelled',
  [POLICY_STATUS.EXPIRED]: 'Cancelled',
  [POLICY_STATUS.ACTIVE]: 'Active',
  [POLICY_STATUS.RENEW_PENDING]: 'Active',
  [POLICY_STATUS.LAPSED]: 'Billing Lapsed',
  [POLICY_STATUS.DECLINED]: 'Renewal Declined',
  [POLICY_STATUS.PENDING]: 'Pending',
};

const ACTIVE = 'activePets';
const INACTIVE = 'inactivePets';

const STATUS_TYPE_MAP = {
  [POLICY_STATUS.ACTIVE]: ACTIVE,
  [POLICY_STATUS.RENEW_PENDING]: ACTIVE,
  [POLICY_STATUS.CANCELLED]: INACTIVE,
  [POLICY_STATUS.DECLINED]: ACTIVE,
  [POLICY_STATUS.PENDING]: ACTIVE,
  [POLICY_STATUS.LAPSED]: ACTIVE,
  [POLICY_STATUS.CANCEL_PENDING]: INACTIVE,
  [POLICY_STATUS.VOIDED]: INACTIVE,
  [POLICY_STATUS.EXPIRED]: INACTIVE,
};

/**
 * Do not import directly into code - exported only for testing
 * */
export function isDeclinedAndCancelled(status, policies) {
  return (
    status === POLICY_STATUS.DECLINED &&
    policies.some(policy => policy.status === POLICY_STATUS.CANCELLED)
  );
}

/*
 TODO: look into just including this in transformPet - blocked by not wanting to mess up redux / old member center
 dawgs2016MemberCenterRedesign included here to just pop this up when removing feature flag
 */
export function reverseSortPolicies(policies) {
  return policies.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
}

export function getPolicyStatusCopy(policies) {
  const sortedPolicies = reverseSortPolicies(policies);
  if (isDeclinedAndCancelled(sortedPolicies[0].status, sortedPolicies)) {
    return STATUS_MAP[POLICY_STATUS.CANCELLED];
  }

  return STATUS_MAP[sortedPolicies[0].status];
}

/**
 * Do not import directly into code - exported only for testing
 * */
export function getPolicyIsRenewal(sortedPolicies) {
  const isRenewal = sortedPolicies[0].status === POLICY_STATUS.RENEW_PENDING;
  const renewalDate = isRenewal
    ? formatISODate(sortedPolicies[0].policy_effective_date, {
        format: 'MMM D, YYYY',
        inputTimezone: 'local',
      })
    : null;

  return { isRenewal, renewalDate };
}

/**
 * Do not import directly into code - exported only for testing
 * */
export function getPolicyIsDeclined(sortedPolicies) {
  const isDeclined =
    sortedPolicies[0].status === POLICY_STATUS.DECLINED &&
    !sortedPolicies.some(policy => policy.status === POLICY_STATUS.CANCELLED);
  const endDate = isDeclined
    ? formatISODate(sortedPolicies[0].policy_end_date, {
        format: 'MMM D, YYYY',
        inputTimezone: 'local',
      })
    : null;

  return { isDeclined, endDate };
}

/**
 * Do not import directly into code - exported only for testing
 * */
export function getPolicyIsCancelled(sortedPolicies) {
  const cancelledPolicy =
    !sortedPolicies.find(policy => policy.status === POLICY_STATUS.ACTIVE) &&
    sortedPolicies.find(
      policy =>
        policy.status === POLICY_STATUS.CANCELLED ||
        policy.status === POLICY_STATUS.CANCEL_PENDING
    );

  const isCancelled =
    !!cancelledPolicy ||
    [POLICY_STATUS.EXPIRED, POLICY_STATUS.VOIDED].includes(sortedPolicies[0].status);
  const isDeceased = cancelledPolicy?.cancellation_reason === 'Deceased';

  return { isCancelled, isDeceased };
}

/**
 * Do not import directly into code - exported only for testing
 * */
export function getPolicyIsWaiting(sortedPolicies) {
  const isWaiting =
    sortedPolicies.length === 1 &&
    sortedPolicies[0].status === POLICY_STATUS.ACTIVE &&
    dateIsBefore(Date.now(), sortedPolicies[0].policy_illness_coverage_start_date);
  const startDate = isWaiting
    ? formatISODate(sortedPolicies[0].policy_illness_coverage_start_date, {
        format: 'MMM D, YYYY',
        inputTimezone: 'local',
      })
    : null;
  const isModelLaw =
    isWaiting &&
    sortedPolicies[0].policy_effective_date ===
      sortedPolicies[0].policy_accident_coverage_start_date;

  return { isWaiting, startDate, isModelLaw };
}

/**
 * Do not import directly into code - exported only for testing
 * */
export function getPolicyIsLapsed(sortedPolicies) {
  const isLapsed = sortedPolicies.some(
    policy => policy.status === POLICY_STATUS.LAPSED
  );

  return { isLapsed };
}

export function getPolicyConfig(policies) {
  const sortedPolicies = reverseSortPolicies(policies);

  const renewal = getPolicyIsRenewal(sortedPolicies);
  const declined = getPolicyIsDeclined(sortedPolicies);
  const cancelled = getPolicyIsCancelled(sortedPolicies);
  const waiting = getPolicyIsWaiting(sortedPolicies);
  const lapsed = getPolicyIsLapsed(sortedPolicies);

  return { renewal, declined, cancelled, waiting, lapsed };
}

export function getPetsByStatus(pets) {
  return pets
    .sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    })
    .reduce(
      (result, pet) => {
        const sortedPolicies = reverseSortPolicies(pet.policies);

        const { status } = sortedPolicies[0];

        if (STATUS_TYPE_MAP[status]) {
          const mappedStatus = isDeclinedAndCancelled(status, pet.policies)
            ? INACTIVE
            : STATUS_TYPE_MAP[status];
          result[mappedStatus].push(pet);
        } else {
          // eslint-disable-next-line no-console
          console.error(`Status ${status} should be accounted for`);
          result[INACTIVE].push(pet);
        }

        return result;
      },
      { [ACTIVE]: [], [INACTIVE]: [] }
    );
}

/*
  returns the latest relevant policy for a pet - there are generally only two recent policies for a pet owner that are relevant:
  the current one and the renewing one
  this returns the current policy (which can include the canceled / expired pets too)
 */
export function getLatestRelevantPolicy(policies, { isSorted } = {}) {
  const sortedPolicies = isSorted ? policies : reverseSortPolicies(policies);

  if (
    [
      POLICY_STATUS.ACTIVE,
      POLICY_STATUS.CANCELLED,
      POLICY_STATUS.CANCEL_PENDING,
      POLICY_STATUS.EXPIRED,
      POLICY_STATUS.LAPSED,
      POLICY_STATUS.VOIDED,
    ].some(status => status === sortedPolicies[0].status)
  )
    return sortedPolicies[0];

  return sortedPolicies[1];
}

export function getPolicyPrice(policies, isAnnuallyCharged) {
  const { premium } = getLatestRelevantPolicy(policies);

  return {
    cost: isAnnuallyCharged ? premium * 12 : premium,
    frequency: isAnnuallyCharged ? 'year' : 'month',
  };
}

export function getPolicyUtilization(policies) {
  return getLatestRelevantPolicy(policies).utilization;
}

/**
 * Do not import directly into code - exported only for testing
 */
export function formatPolicyDocuments(policyChanges, policyDocuments) {
  // if no document_id, there is no s3 doc
  const policyChangesFiltered = policyChanges
    .filter(policy => policy.document_id !== null)
    .map(change => ({ ...change, isChange: true }));

  const mergedPolicyDocuments = [...policyChangesFiltered, ...policyDocuments];

  const sortedMergedPolicyDocuments = mergedPolicyDocuments.sort(
    (a, b) =>
      new Date(b.processed_at ? b.processed_at : b.created_at) -
      new Date(a.processed_at ? a.processed_at : a.created_at)
  );

  return sortedMergedPolicyDocuments;
}

export function getPolicyDocuments(policies) {
  const allowedStatuses = [
    POLICY_STATUS.ACTIVE,
    POLICY_STATUS.CANCELLED,
    POLICY_STATUS.CANCEL_PENDING,
    POLICY_STATUS.EXPIRED,
    POLICY_STATUS.VOIDED,
  ];
  const filteredPolicies = policies.filter(policy =>
    allowedStatuses.some(status => status === policy.status)
  );
  const reverseSortedPolicies = reverseSortPolicies(filteredPolicies);

  return reverseSortedPolicies.reduce((result, policy, idxPolicy) => {
    const sortedDocuments = formatPolicyDocuments(
      policy.policy_changes,
      policy.documents
    );

    sortedDocuments.forEach((document, idxDoc) => {
      /*
       only add documents if there will be an s3 document to return (policy_change already filters for that)
       original policy doc relies on document.location (or policy.policy_s3_path which should be deprecated)
       however, for first document in the list, we should always add it and instead render copy about how doc is still
       being generated
       */
      if (
        document.isChange ||
        document.location ||
        (idxPolicy === 0 && idxDoc === 0)
      ) {
        result.push({
          ...document,
          name: document.isChange
            ? `Updated Insurance Policy ${formatISODate(
                document.processed_at || document.created_at,
                { format: 'MMM D, YYYY' }
              )}`
            : 'Insurance Policy',
          policy_id: policy.id,
          subtitle: `${formatISODate(policy.policy_effective_date, {
            format: 'MMM D, YYYY',
            inputTimezone: 'local',
          })} - ${formatISODate(policy.policy_end_date, { format: 'MMM D, YYYY' })}`,
          processingText:
            !document.isChange && !document.location
              ? "Your latest policy document isn't ready. Hang tight and check back soon."
              : '',
        });
      }
    });

    return result;
  }, []);
}
