import { formatISODate } from '@pumpkincare/shared';

export const PEP_PLAN_STATUS = {
  ACTIVE: 'active',
  RENEW_PENDING: 'renew_pending',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  VOIDED: 'voided',
  DECLINED: 'declined',
  PENDING: 'pending',
  LAPSED: 'lapsed',
  CANCEL_PENDING: 'cancel_pending',
};

/*
 TODO: look into just including this in transformPet - blocked by not wanting to mess up redux / old member center
 dawgs2016MemberCenterRedesign included here to just pop this up when removing feature flag
 */
export function reverseSortPlans(plans) {
  return plans.sort(
    (a, b) => new Date(b.plan_effective_date) - new Date(a.plan_effective_date)
  );
}

/*
  returns the latest relevant plan for a pet - there are generally only two recent plans for a pet owner that are relevant:
  the current one and the renewing one
  this returns the current plan (which can include the canceled / expired pets too)
 */
export function getLatestRelevantPlan(plans, { isSorted } = {}) {
  if (!plans.length) return {};

  const sortedPlans = isSorted ? plans : reverseSortPlans(plans);

  if (
    [
      PEP_PLAN_STATUS.ACTIVE,
      PEP_PLAN_STATUS.CANCELLED,
      PEP_PLAN_STATUS.CANCEL_PENDING,
      PEP_PLAN_STATUS.EXPIRED,
      PEP_PLAN_STATUS.LAPSED,
      PEP_PLAN_STATUS.VOIDED,
    ].some(status => status === sortedPlans[0].status)
  )
    return sortedPlans[0];

  return sortedPlans[1];
}

export function getPlanPrice(plans, isAnnuallyCharged) {
  if (!plans.length) return {};

  const { plan } = getLatestRelevantPlan(plans);

  return {
    cost: isAnnuallyCharged ? plan.cost * 12 : plan.cost,
    frequency: isAnnuallyCharged ? 'year' : 'month',
  };
}

export function getPlanEntitlements(plans) {
  if (!plans.length) return [];

  const { plan } = getLatestRelevantPlan(plans);

  return plan.entitlements.flatMap(entitlement => entitlement.items);
}

export function getPlanDocuments(plans) {
  if (!plans.length) return [];

  const allowedStatuses = [
    PEP_PLAN_STATUS.ACTIVE,
    PEP_PLAN_STATUS.CANCELLED,
    PEP_PLAN_STATUS.CANCEL_PENDING,
    PEP_PLAN_STATUS.EXPIRED,
    PEP_PLAN_STATUS.VOIDED,
  ];
  const filteredPlans = plans.filter(plan =>
    allowedStatuses.some(status => status === plan.status)
  );

  const sortedPlans = reverseSortPlans(filteredPlans);

  // TODO: mimic getPolicyDocuments and change processingText to properly reflect if the first plan has an s3 path that can be downloaded
  return sortedPlans.map(plan => ({
    id: plan.id,
    name: 'Customer Agreement',
    subtitle: `${formatISODate(plan.plan_effective_date, {
      format: 'MMM D, YYYY',
      inputTimezone: 'local',
    })} - ${formatISODate(plan.plan_end_date, {
      format: 'MMM D, YYYY',
      inputTimezone: 'local',
    })}`,
    processingText: '',
    // processingText: someCondition ? 'Your latest customer agreement isn't ready. Hang tight and check back soon.' : '',
  }));
}
