import { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import VetClinicForm from './vet-clinic-form';
import VetClinicSearch from './vet-clinic-search';

function VetClinic(props) {
  const { onChange, zipCode, selectedVet, classes, ...rest } = props;

  const payload = useRef({
    valid: false,
    existing: selectedVet,
    custom: {},
  });
  const [isCustomInfo, setIsCustomInfo] = useState(false);
  const [name, setName] = useState('');

  function setCustom(valid, data) {
    payload.current = {
      valid: valid,
      existing: null,
      custom: { ...payload.current.custom, ...data },
    };
    onChange(payload.current);
  }

  function reset() {
    payload.current = {
      valid: false,
      existing: null,
      custom: {},
    };
    onChange(payload.current);
    setName('');
  }

  // When selecting a clinic from the list, pass the ID
  function handleVetClinicSelect(clinic) {
    payload.current = {
      valid: true,
      existing: clinic,
      custom: {},
    };
    onChange(payload.current);

    setName(clinic.vet_name);
    setIsCustomInfo(false);
  }

  // When clicking in the clear button, reset the component
  function handleVetClinicClear() {
    reset();

    setIsCustomInfo(false);
  }

  // When user wants to input a new clinic
  function handleVetClinicEnterInfo({ value }) {
    setCustom(false, {});

    setIsCustomInfo(true);
    setName(value);
  }

  // When form changes
  function onFormChange({ valid, payload }) {
    setCustom(valid, payload);
  }

  // When inputing a custom, if the user clicks on cancel, reset the component
  function onFormCancel() {
    reset();

    setIsCustomInfo(false);
  }

  return (
    <Fragment>
      {!isCustomInfo ? (
        <VetClinicSearch
          className={props.className}
          defaultValue={selectedVet?.vet_name}
          onChange={setName}
          onClear={handleVetClinicClear}
          onSelect={handleVetClinicSelect}
          onEnterInfo={handleVetClinicEnterInfo}
          zipCode={zipCode}
          classes={classes}
          {...rest}
        />
      ) : (
        <VetClinicForm
          onChange={onFormChange}
          onCancel={onFormCancel}
          defaultValue={name}
          className={props.className}
        />
      )}
    </Fragment>
  );
}

VetClinic.defaultProps = {
  onChange: () => {},
};

VetClinic.propTypes = {
  onChange: PropTypes.func,
  selectedVet: PropTypes.object,
  zipCode: PropTypes.string,
  classes: PropTypes.object,
};

export default VetClinic;
