import { useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import LegalBody from '../../typography/legal-body';

import Typography from '../../typography.module.css';
import styles from './stripe-element-wrapper.css';

function StripeElementWrapper({
  component: Component,
  label,
  id,
  onChange,
  onError,
  classes,
}) {
  // can add in focused, empty, other states as needed
  const [{ error }, setState] = useState({
    error: false,
  });

  function handleChange({ empty, error, complete }) {
    setState(current => ({
      ...current,
      error,
    }));

    onError(_.get(error, 'message', ''));
    onChange(id, empty, complete);
  }

  const errorText = _.get(error, 'message', '');
  const styleOverwrite = {
    base: {
      fontSize: '14px',
      fontFamily: 'Nunito Sans',
      color: 'var(--scoutNavy)',
    },
  };
  // !important doesnt work as a css overwrite

  return (
    <div className={classNames(styles.root, classes.root)}>
      <label
        className={classNames(styles.label, Typography.legalBody, classes.label)}
        htmlFor={id}
      >
        {label}
      </label>

      <Component
        onChange={handleChange}
        label={label}
        id={id}
        options={{
          placeholder: '',
          classes: {
            base: styles.base,
            focus: styles.focus,
            invalid: styles.invalid,
          },
          style: styleOverwrite,
        }}
      />

      {errorText ? (
        <LegalBody id='my-helper-text' className={styles.errorMessage}>
          {errorText}
        </LegalBody>
      ) : null}
    </div>
  );
}
// https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options

StripeElementWrapper.defaultProps = {
  classes: {},
  onChange: () => {},
  onError: () => {},
};

StripeElementWrapper.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
  }),

  component: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onError: PropTypes.func,
};

export default StripeElementWrapper;
