import { Auth } from 'aws-amplify';
import axios from 'axios';

import { transformAttachment } from '@pumpkincare/claims';
import {
  configureAmplifyCustomAuth,
  configureAmplifyUserSrpAuth,
  POLICY_BASE_API,
} from '@pumpkincare/config';
import { buildAuthHeaders, responseDataBody } from '@pumpkincare/shared';

import { transformPayment } from './user-utils';

export function logIn(email, password) {
  configureAmplifyUserSrpAuth();

  return Auth.signIn(email.toLowerCase(), password).catch(error => {
    if (error.code === 'UserNotFoundException') {
      return Auth.signIn(email, password);
    } else {
      throw error;
    }
  });
}

export function customSignInToCognito(email, access_token) {
  configureAmplifyCustomAuth();

  return Auth.signIn(email).then(user => {
    if (user.challengeName === 'CUSTOM_CHALLENGE') {
      return Auth.sendCustomChallengeAnswer(
        user,
        `vet_survey_token::${access_token}`
      );
    }
  });
}

export function logOut() {
  return Auth.signOut();
}

export function putUserAch(body) {
  return Auth.currentAuthenticatedUser().then(user =>
    axios
      .put(`${POLICY_BASE_API}/users/ach`, body, buildAuthHeaders(user))
      .then(responseDataBody)
  );
}

export function deleteUserAch() {
  return Auth.currentAuthenticatedUser().then(user =>
    axios.delete(`${POLICY_BASE_API}/users/ach`, buildAuthHeaders(user))
  );
}

export function getMonthlyStatements() {
  return Auth.currentAuthenticatedUser().then(user =>
    axios
      .get(`${POLICY_BASE_API}/users/monthly-statements`, buildAuthHeaders(user))
      .then(responseDataBody)
  );
}

export function getUserPaymentMethod() {
  return Auth.currentAuthenticatedUser().then(user =>
    axios
      .get(`${POLICY_BASE_API}/users/payment_method`, buildAuthHeaders(user))
      .then(responseDataBody)
      .then(transformPayment)
  );
}

export function putUserPaymentMethod(stripeToken) {
  return Auth.currentAuthenticatedUser().then(user =>
    axios.put(
      `${POLICY_BASE_API}/users/payment_method`,
      { stripeToken },
      buildAuthHeaders(user)
    )
  );
}

export function postChargeLapsedUser(userId) {
  const url = `${POLICY_BASE_API}/users/${userId}/charge-lapsed-invoices`;

  return Auth.currentAuthenticatedUser().then(user =>
    axios.post(url, {}, buildAuthHeaders(user))
  );
}

export function postMedicalHistory(userId, petId, medicalRecord) {
  return Auth.currentAuthenticatedUser().then(user =>
    axios
      .post(
        `${POLICY_BASE_API}/users/${userId}/pets/${petId}/medical-record`,
        medicalRecord,
        buildAuthHeaders(user)
      )
      .then(responseDataBody)
  );
}

export function getUserSelf() {
  return Auth.currentAuthenticatedUser().then(user =>
    axios
      .get(`${POLICY_BASE_API}/users/self`, buildAuthHeaders(user))
      .then(responseDataBody)
  );
}

export function getUserProfile() {
  return Auth.currentAuthenticatedUser().then(user =>
    axios
      .get(`${POLICY_BASE_API}/users/profile`, buildAuthHeaders(user))
      .then(responseDataBody)
  );
}

export function updateUserPaperless({ is_paperless, terms_version }) {
  return Auth.currentAuthenticatedUser().then(user =>
    axios
      .patch(
        `${POLICY_BASE_API}/users/paperless`,
        { is_paperless, terms_version },
        buildAuthHeaders(user)
      )
      .then(responseDataBody)
  );
}

export function downloadPolicy(policyId) {
  const path = `${POLICY_BASE_API}/policies/policy-url/${policyId}`;

  return Auth.currentAuthenticatedUser().then(user =>
    axios
      .get(path, buildAuthHeaders(user))
      .then(responseDataBody)
      .then(result => result.url)
  );
}

export function downloadUpdatedPolicy(policyId, documentId) {
  const path = `${POLICY_BASE_API}/policies/${policyId}/documents/${documentId}/document-url`;

  return Auth.currentAuthenticatedUser().then(user =>
    axios
      .get(path, buildAuthHeaders(user))
      .then(responseDataBody)
      .then(result => result.url)
  );
}

export function changeEmail(newEmail) {
  return Auth.currentAuthenticatedUser().then(user => {
    const url = `${POLICY_BASE_API}/users/${user.getUsername()}/change-email-address`;

    return axios
      .put(url, { email: newEmail }, buildAuthHeaders(user))
      .then(response => {
        if (response.status === 204) {
          return response;
        } else {
          throw 'Unknown response from server';
        }
      });
  });
}

export function fetchUserVets(userId) {
  return Auth.currentAuthenticatedUser().then(user => {
    const url = `${POLICY_BASE_API}/users/${userId}/vets`;

    return axios.get(url, buildAuthHeaders(user)).then(responseDataBody);
  });
}

export function postUserVets(userId, vetId, body) {
  return Auth.currentAuthenticatedUser().then(user => {
    const url = `${POLICY_BASE_API}/users/${userId}/vets/${vetId}`;

    return axios.post(url, body, buildAuthHeaders(user)).then(responseDataBody);
  });
}

export function putUserVets(userId, vetId, body) {
  return Auth.currentAuthenticatedUser().then(user => {
    const url = `${POLICY_BASE_API}/users/${userId}/vets/${vetId}`;

    return axios.put(url, body, buildAuthHeaders(user)).then(responseDataBody);
  });
}

export function uploadFile(file, rawFile, type) {
  return obtainSignedUrlForFiles([rawFile], type)
    .then(signedUrls => {
      const signedUrlParams = signedUrls[file.name];

      file.key = signedUrlParams.fields.key;

      return uploadAttachments([rawFile], signedUrls);
    })
    .then(() => {
      return file;
    });
}

export function obtainSignedUrlForFiles(files, type) {
  return Auth.currentAuthenticatedUser().then(user => {
    const fileNames = files.map(file => file.name);

    return axios
      .post(
        `${POLICY_BASE_API}/users/signed-url`,
        {
          files: fileNames,
          file_type: type,
        },
        buildAuthHeaders(user)
      )
      .then(response => response.data);
  });
}

export function postSignedUrlForPetPhoto(fileName) {
  return Auth.currentAuthenticatedUser().then(user => {
    return axios
      .post(
        `${POLICY_BASE_API}/users/signed-url/pet-photo`,
        { file: fileName },
        buildAuthHeaders(user)
      )
      .then(responseDataBody);
  });
}

export function uploadAttachments(files, signedUrls) {
  const uploadPromises = files.map(file => {
    const [postUrl, formData] = transformAttachment(file, signedUrls);

    return axios.post(postUrl, formData);
  });

  return Promise.all(uploadPromises);
}

export function downloadPlanDoc(petPlanId) {
  const path = `${POLICY_BASE_API}/pet-plans/${petPlanId}/doc`;

  return Auth.currentAuthenticatedUser().then(user =>
    axios.get(path, buildAuthHeaders(user)).then(response => {
      return response.data.body.url;
    })
  );
}

export function changePassword(oldPassword, newPassword) {
  return Auth.currentAuthenticatedUser().then(user =>
    Auth.changePassword(user, oldPassword, newPassword)
  );
}

export function checkUserToken(params) {
  const { token, tokenType = 'password_signup' } = params;
  const url = `${POLICY_BASE_API}/users/check-token?token=${token}&type=${tokenType}`;

  return axios.post(url, {});
}

export function forgotPassword(email) {
  const url = `${POLICY_BASE_API}/users/forgot-password`;

  return axios.post(url, { email });
}

export function postUserVet(vet_id) {
  const url = `${POLICY_BASE_API}/users/vets`;

  return Auth.currentAuthenticatedUser().then(user => {
    return axios.post(url, { vet_id }, buildAuthHeaders(user));
  });
}

export function legacyPutUserAddress(userId, billingAddress) {
  return Auth.currentAuthenticatedUser().then(user => {
    return axios.put(
      `${POLICY_BASE_API}/users/${userId}/addresses/${billingAddress.id}`,

      billingAddress,

      buildAuthHeaders(user)
    );
  });
}

export function postCheckEmail(email) {
  const url = `${POLICY_BASE_API}/users/check_email`;

  return axios.post(url, { email }).then(response => {
    const { exists } = responseDataBody(response);

    return { exists };
  });
}

export function getUserBranding(userId) {
  const url = `${POLICY_BASE_API}/users/${userId}/branding`;

  return axios.get(url).then(responseDataBody);
}

export function getUserAddresses() {
  const url = `${POLICY_BASE_API}/users/addresses`;

  return Auth.currentAuthenticatedUser().then(user => {
    return axios.get(url, buildAuthHeaders(user)).then(responseDataBody);
  });
}

export function putUserBillingAddress(address) {
  const { country, state, city, street_1, street_2, zipcode } = address;
  const url = `${POLICY_BASE_API}/users/addresses/billing`;

  return Auth.currentAuthenticatedUser().then(user => {
    return axios.put(
      url,
      {
        country,
        state,
        city,
        street_1,
        street_2,
        zipcode,
      },

      buildAuthHeaders(user)
    );
  });
}

export function getUserPetPhotos() {
  return Auth.currentAuthenticatedUser().then(user =>
    axios
      .get(`${POLICY_BASE_API}/users/pets/pet-photos`, buildAuthHeaders(user))
      .then(responseDataBody)
  );
}

export function getUserPetPhotosById(petId) {
  return Auth.currentAuthenticatedUser().then(user =>
    axios
      .get(
        `${POLICY_BASE_API}/users/pets/${petId}/pet-photos`,
        buildAuthHeaders(user)
      )
      .then(responseDataBody)
  );
}

export const UserService = {
  changeEmail,
  fetchUserVets,
  postUserVets,
  postSignedUrlForPetPhoto,
  putUserVets,
  uploadAttachments,
  obtainSignedUrlForFiles,
  checkUserToken,
  forgotPassword,
};
