import { useQuery } from 'react-query';

import { getIsLoggedIn } from '@pumpkincare/shared';

import { getUserProfile } from './user-service';

export const USER_PROFILE_QUERY = 'userProfile';

export const getUserIsPaperless = user => user.is_paperless;
export const getUserTermsVersion = user => user.terms_version;

export function useUserProfile(select) {
  return useQuery(
    [USER_PROFILE_QUERY],

    getUserProfile,

    {
      enabled: getIsLoggedIn(),

      placeholderData: {
        is_paperless: null,
        terms_version: null,
      },
      staleTime: 1000 * 60 * 60,

      select,
    }
  );
}
