import { useQuery } from 'react-query';

import { getUserPetPhotosById } from './user-service';

export const USER_PET_PHOTO_QUERY = 'userPetPhotoQuery';

export function useUserPetPhoto(petId) {
  return useQuery(
    [USER_PET_PHOTO_QUERY, petId],

    () => getUserPetPhotosById(petId),

    {
      enabled: !!petId,
      placeholderData: {
        id: '',
        location: '',
      },
      staleTime: 1000 * 60 * 60,
    }
  );
}
