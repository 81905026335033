import { useMutation, useQueryClient } from 'react-query';

import { setTrackedCustomProps } from '@pumpkincare/analytics';

import { USER_SELF_QUERY } from './user-self-query';
import { changeEmail, changePassword } from './user-service';

export function useMutateUserEmail() {
  const queryClient = useQueryClient();

  return useMutation(email => changeEmail(email), {
    onMutate: async email => {
      await queryClient.cancelQueries([USER_SELF_QUERY]);

      setTrackedCustomProps({ email });
      const self = queryClient.getQueryData([USER_SELF_QUERY]);
      queryClient.setQueryData([USER_SELF_QUERY], {
        ...self,

        email,
      });

      return { prevData: email };
    },
  });
}

export function useMutateUserPassword() {
  return useMutation(({ password, newPassword }) => {
    return changePassword(password, newPassword);
  });
}
