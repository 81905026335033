import { useQuery } from 'react-query';

import { analyticsIdentify, setTrackedCustomProps } from '@pumpkincare/analytics';
import { getIsLoggedIn } from '@pumpkincare/shared';

import { getUserSelf } from './user-service';
import { transformUser } from './user-utils';

export const USER_SELF_QUERY = 'userSelf';

export const getUserEmail = user => user.email;
export const getUserExternalId = user => user.externalId;
export const getUserFirstName = user => user.firstName;
export const getUserId = user => user.id;
export const getUserInvoices = user => user.invoices;
export const getUserIsChargedAnnually = user => user.isChargedAnnually;
export const getUserLapsedSince = user => user.lapsedSince;
export const getUserLastName = user => user.lastName;
export const getUserMonthlyChargeDay = user => user.monthlyChargeDay;
export const getUserNextChargeDate = user => user.nextChargeDate;
export const getUserPets = user => user.pets;
export const getUserPhone = user => user.phone;
export const getUserPlans = user => user.plans;
export const getUserPolicies = user => user.policies;
export const getUserTransactionFee = user => user.transactionFee;

export const USER_INITIAL_DATA = {
  email: '',
  externalId: '',
  firstName: '',
  id: '',
  invoices: [],
  isChargedAnnually: false,
  lapsedSince: null,
  lastName: '',
  monthlyChargeDay: null,
  nextChargeDate: null,
  pets: [],
  phone: '',
  plans: [],
  policies: [],
  transactionFee: 0,
};

export function getUserQueryFn(onSuccess = () => {}) {
  return getUserSelf() // "users" is actually just a user
    .then(({ users: user, invoices }) => {
      onSuccess({ users: user, invoices });
      return transformUser(user, invoices);
    })
    .then(user => {
      setTrackedCustomProps({
        userId: user.id,
        userPets: user.pets,
        email: user.email,
      });
      analyticsIdentify(user, user.id);

      return user;
    });
}

export function useUssr(select) {
  return useQuery(
    [USER_SELF_QUERY],

    () => getUserQueryFn(),

    {
      cacheTime: Infinity,
      staleTime: 1000 * 60 * 10,
      enabled: getIsLoggedIn(),

      placeholderData: USER_INITIAL_DATA,

      select,
    }
  );
}
