// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stripe-element-wrapper_root_880ac33c {\n  position: relative;\n  width: 100%;\n}\n\n.stripe-element-wrapper_base_cfcde6f5 {\n  width: 100%;\n  cursor: text;\n  z-index: 0;\n  border: 1px solid var(--pusheenGray);\n  padding: 14.6px 12px;\n  background: var(--zeroWhite);\n  border-radius: 8px;\n  box-sizing: border-box;\n  font-size: 14px;\n}\n\n.stripe-element-wrapper_focus_15a5d2d6 {\n  border: 2px solid var(--bluesCluesBlue);\n}\n\n.stripe-element-wrapper_invalid_fa7e27cc {\n  border: 2px solid var(--crookshanksRed) !important;\n}\n\n.stripe-element-wrapper_focus_15a5d2d6, .stripe-element-wrapper_invalid_fa7e27cc {\n  padding: 13.6px 11px;\n}\n\n.stripe-element-wrapper_errorMessage_abae1174 {\n  color: var(--crookshanksRed);\n}\n\n.stripe-element-wrapper_label_531336a5 {\n  color: var(--scoutNavy);\n  font-weight: 800;\n  cursor: text;\n}", "",{"version":3,"sources":["webpack://../../libs/shared/src/ui/inputs/stripe-element-wrapper/stripe-element-wrapper.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,UAAU;EACV,oCAAoC;EACpC,oBAAoB;EACpB,4BAA4B;EAC5B,kBAAkB;EAClB,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,kDAAkD;AACpD;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".root {\n  position: relative;\n  width: 100%;\n}\n\n.base {\n  width: 100%;\n  cursor: text;\n  z-index: 0;\n  border: 1px solid var(--pusheenGray);\n  padding: 14.6px 12px;\n  background: var(--zeroWhite);\n  border-radius: 8px;\n  box-sizing: border-box;\n  font-size: 14px;\n}\n\n.focus {\n  border: 2px solid var(--bluesCluesBlue);\n}\n\n.invalid {\n  border: 2px solid var(--crookshanksRed) !important;\n}\n\n.focus, .invalid {\n  padding: 13.6px 11px;\n}\n\n.errorMessage {\n  color: var(--crookshanksRed);\n}\n\n.label {\n  color: var(--scoutNavy);\n  font-weight: 800;\n  cursor: text;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "stripe-element-wrapper_root_880ac33c",
	"base": "stripe-element-wrapper_base_cfcde6f5",
	"focus": "stripe-element-wrapper_focus_15a5d2d6",
	"invalid": "stripe-element-wrapper_invalid_fa7e27cc",
	"errorMessage": "stripe-element-wrapper_errorMessage_abae1174",
	"label": "stripe-element-wrapper_label_531336a5"
};
export default ___CSS_LOADER_EXPORT___;
